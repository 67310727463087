@import 'spacings';

.deleteButton {
  display: block;
  margin-top: units(1);
}

.accessibleCheckbox {
  input[type='checkbox'],
  label[for='accessible'] {
    cursor: default;
  }
}
