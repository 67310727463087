@import 'fonts';
@import 'colours';

.standard {
  color: inherit;
}

.brand {
  color: $blue;
}

.critical {
  color: $error;
}

.secondary {
  color: $black-20;
}

.gray {
  color: $black-70;
}

.bold {
  font-weight: bold;
}

.normalWeight {
  font-weight: normal;
}

.light {
  font-weight: lighter;
}

.italic {
  font-style: italic;
}

.underlined {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.xs {
  font-size: font-size(12);
}

.s {
  font-size: font-size(14);
}

.m {
  font-size: font-size(16);
}

.l {
  font-size: font-size(18);
}

.xl {
  font-size: font-size(20);
}

.xxl {
  font-size: font-size(24);
}

.xxxl {
  font-size: font-size(30);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
}
