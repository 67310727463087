@import 'spacings';

.container {
  padding: units(0.75) 0 units(0.25);

  display: flex;
  align-items: flex-start;
}

.filterList {
  flex-grow: 1;

  list-style: none;

  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 units(0.5) units(0.5) 0;
  }
}

.resetFiltersButton {
  margin-top: units(0.25);
}
