@import 'colours';
@import 'spacings';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  flex-grow: 1;
  font-weight: bold;
  padding: units(1);
}

.selectContainer {
  color: $white;
  background-color: $blue;
  display: flex;
  justify-content: space-between;
}

.button {
  text-transform: uppercase;
  padding: units(1);
  margin: 0 units(1);
}

.editButton {
  padding: units(1);
}

.buttonSelected {
  font-weight: bold;
  border-bottom: units(0.25) solid $white;
}
