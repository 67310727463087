@import 'normalize';
@import 'boxSizing';
@import 'colours';
// Note: the HDS related variables and styles are included via "hds-react" dependecy (it injects the CSS with JS)

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/565d73a693abe0776c801607ac28f0bf.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/5bb29e3b7b1d3ef30121229bbe67c3e1.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/7c46f288e8133b87e6b12b45dac71865.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/e62dc97e83a385e4d8cdc939cf1e4213.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/533af26cf28d7660f24c2884d3c27eac.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/20d494430c87e15e194932b729d48270.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/a50a1bd245ce63abcc0d1da80ff790d2.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HelsinkiGrotesk';
  src: url('https://makasiini.hel.ninja/delivery/HelsinkiGrotesk/62a1781d8b396fbb025b0552cf6304d2.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

html * {
  font-family: var(--font-default), Arial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  color: inherit;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: inherit;
  }
}
