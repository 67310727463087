@import 'spacings';

.noticeView {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-gap: units(1.5);
}

.fullWidth {
  grid-column: 1 / -1;
}

.detailsActions {
  text-align: right;
}
