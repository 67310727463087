@import 'colours';
@import 'spacings';

.column {
  margin: units(1);
}

.image {
  max-width: 100%;
}

.address {
  margin-bottom: units(1.5);
}
