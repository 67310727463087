$white: var(--color-white);
$black: var(--color-black);

$blue-light-20: var(--color-coat-of-arms-light);
$blue-light-50: var(--color-coat-of-arms-medium-light);
$blue: var(--color-coat-of-arms);
$blue-dark-50: var(--color-coat-of-arms-dark);

$black-5: var(--color-black-5);
$black-10: var(--color-black-10);
$black-20: var(--color-black-20);
$black-30: var(--color-black-30);
$black-40: var(--color-black-40);
$black-50: var(--color-black-50);
$black-60: var(--color-black-60);
$black-70: var(--color-black-70);
$black-80: var(--color-black-80);
$black-90: var(--color-black-90);

$error: var(--color-error);
$error-light: var(--color-error-light);
$success: var(--color-success);
$success-light: var(--color-success-light);
$alert: var(--color-alert);
$alert-light: var(--color-alert-light);
$alert-dark: var(--color-alert-dark);
$info: var(--color-info);
$info-light: var(--color-info-light);

$orange-50: var(--color-metro);
