@import 'spacings';

.cardBody {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.details {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 60%;
}

.imageWrapper {
  width: 270px;
  min-width: 270px;
  margin-right: units(1.5);
}

.image {
  width: 100%;
  max-height: 100%;
}

.propsGrid {
  grid-row-gap: units(1.5);
  grid-column-gap: units(0.5);
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.wordBreak {
  overflow-wrap: break-word;
  width: 100%;
  hyphens: auto;
}
