@import 'spacings';

.actions {
  grid-column: 1 / -1;
  grid-template-areas: 'l status r';
  grid-template-columns: 3fr 6fr 3fr;
  display: grid;
  width: 100%;
}

.noticeStatus {
  width: 100%;
  text-align: center;
  grid-area: status;
  align-self: center;
  h2 {
    display: inline;
    margin-right: units(1);
  }
}

.actionsRight {
  display: inline-flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: units(1);
  grid-area: r;
  text-align: right;
}
