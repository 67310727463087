@import 'fonts';
@import 'spacings';

.list {
  margin: 0;
  padding-left: units(2);
}

.noBullets {
  list-style: none;
  padding-left: 0;
}

.small {
  font-size: font-size(14);
}

.large {
  font-size: font-size(20);
}
