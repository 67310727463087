@import 'containers';
@import 'spacings';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContainer {
  @extend .container;
  position: relative;
  top: 20%;
  max-width: container(s);
  text-align: center;
}

.heading {
  margin: units(2) 0;
}

.text {
  margin: units(2) 0;
}
