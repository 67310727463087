@import 'spacings';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: units(0.75);
}

.filtersContainer {
  display: flex;
  flex-direction: row;
}

.leftContainer {
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: units(0.5);
  }
}

.select {
  margin-left: units(1);
  width: 250px;
}
