@import 'spacings';

.section {
  padding-top: units(0.75);
  padding-bottom: units(0.75);
}

.title {
  margin-bottom: units(1);
}

.body {
  & > * {
    margin-bottom: units(0.5);
  }
}

.sectionHeadWithButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
