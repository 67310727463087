$project-font-size: (
  '12': 12px,
  '14': 14px,
  '16': 16px,
  '18': 18px,
  '20': 20px,
  '24': 24px,
  '30': 30px,
  '36': 36px,
  '48': 48px,
  '60': 60px,
  '72': 72px,
);

/*
===================================
    Usage
===================================
.element {
  font-size: font-size(16);
}
*/

@function font-size($value) {
  $converted: if(type-of($value) == 'string', $value, inspect($value));

  @if not map-has-key($project-font-size, $converted) {
    @error '`#{$value}` is not a valid font size token. '
            + 'Valid font size tokens: '
            + '#{map-keys($project-font-size)}';
  }

  @return map-get($project-font-size, $converted);
}
