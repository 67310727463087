@import 'colours';
@import 'spacings';

.dataSummary {
  display: flex;
  flex-wrap: wrap;
  border-style: solid;
  border-color: $black-50;
  border-width: 1px 0 0 1px;
}

.cell {
  min-width: units(12);
  flex-grow: 1;
  flex-basis: 0;
  padding: units(1) units(1.5);
  border-style: solid;
  border-color: $black-50;
  border-width: 0 1px 1px 0;
}
