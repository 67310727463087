@import 'spacings';

.properties {
  display: grid;
  margin-right: units(0.5);
  gap: units(0.5);
  grid-template-columns: repeat(auto-fit, minmax(units(4), 1fr));
}

.property {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
}
