@import 'containers';
@import 'spacings';

.createLeaseModal {
  width: container(m);

  .body {
    padding: units(2) 0;
  }

  .select {
    width: 100%;
    margin-bottom: units(2);
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
