@import 'containers';
@import 'spacings';
@import 'z-index';

.container {
  position: absolute;
  bottom: 0;
  right: units(1);
  width: container(s);
  z-index: z-index(toasts);
  margin: units(1) 0;
}
