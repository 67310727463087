@import 'spacings';

.listItem {
  margin-bottom: units(0.25);
}

.actions {
  margin-top: units(2);
  text-align: right;
}
