@import 'spacings';
@import 'fonts';

.formTypeTitleLabel {
  font-size: font-size(14);
  font-weight: bold;
}

.formTypeTitleValue {
  margin-top: units(0.25);
  font-size: font-size(18);
}
