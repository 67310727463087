@import 'colours';
@import 'spacings';

.header {
  background-color: $blue;
  padding: units(0.5) units(2);
  min-height: units(4);
  color: $white;
  fill: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
