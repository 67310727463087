@import 'containers';
@import 'spacings';

.form {
  width: container(m);
  > div {
    margin: units(1) 0;
  }
}

.recipientCount {
  margin-top: units(1);
  margin-left: units(2);
  line-height: units(2);
  height: units(2);
  align-self: center;
}

.formActionButtons {
  display: flex;
  justify-content: space-between;
}

.formActionButtonsRight {
  display: flex;
  justify-content: flex-end;

  > button {
    margin-left: units(0.5);
  }
}

.message {
  textarea {
    // HDS doesn't provide height prop at the moment
    height: 30vh !important;
  }
}
