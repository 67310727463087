@import 'spacings';

.tableTools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: units(1);
}

.statusLabel {
  margin-left: units(0.5);
}

.gridItem {
  margin-left: units(1);
}
