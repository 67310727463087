@import 'colours';
@import 'spacings';

.mainLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.serviceName {
  color: $white;
  margin: 0 0 0 units(1.5);
}
