@import 'spacings';
@import 'colours';

.cardHeader {
  background-color: $blue;
  padding: units(1);
  font-weight: bold;
  color: $white;
  display: flex;
  align-items: center;
}

.title {
  flex-grow: 1;
}

.widgets {
  display: flex;
}
