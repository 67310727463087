@import 'colours';
@import 'spacings';
@import 'z-index';

.dropdown {
  cursor: pointer;
  position: relative;
}

.menu {
  position: absolute;
  background-color: $black-20;
  padding: units(0.75);
  color: $black;
  margin-top: units(1);
  min-width: 100%;
  z-index: z-index(dropdown);
}
