@import 'colours';
@import 'spacings';

.globalSearchTableTools {
  display: flex;
  justify-content: flex-end;
  margin-bottom: units(0.5);
}

.filterField {
  input {
    background-color: $white;
  }
  width: 22em;
  min-width: units(12);
}
