@import 'spacings';
@import 'colours';

.tableTools {
  margin-bottom: units(0.75);
  display: flex;
  justify-content: space-between;
}

.tableToolsLeft {
  display: flex;
  align-items: center;

  > * {
    margin: 0 units(0.5);
  }

  :first-child {
    margin-left: 0;
  }
}

.tableToolsRight {
  justify-content: flex-end;
  display: flex;

  > * {
    margin: 0 units(0.5);
  }

  :last-child {
    margin-right: 0;
  }
}

.select {
  width: units(12);
}

.searchInput {
  input {
    background-color: $white;
  }
  min-width: units(12);
}
