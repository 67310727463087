@import 'spacings';
@import 'colours';
@import 'z-index';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: z-index(modal);
}

.modal {
  background-color: $white;
  box-shadow: 0 0 units(0.25) $black;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding: units(3);
  position: relative;
  &:focus {
    outline: none;
  }
}
