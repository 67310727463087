@import 'spacings';
@import 'containers';

.invoiceModal {
  width: container(m);

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: units(3);
  }

  .select {
    width: 24em;
  }

  .divider {
    width: 100%;
  }

  .closeButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: units(2);
  }

  .statusLabel {
    position: absolute;
    top: 0;
    left: 0;
  }

  .bold {
    font-weight: bold;
  }
}
