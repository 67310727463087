@import 'spacings';

.container {
  margin-bottom: units(0.75);
  display: flex;
  align-items: center;

  > * {
    margin-right: units(0.5);
  }
}

.buttonContainer {
  display: flex;
  align-items: center;

  > * {
    margin: 0 units(0.5);
  }
}

.select {
  width: 250px;
}
