@import 'spacings';

.applicationView {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-gap: units(1.5);
}

.fullWidth {
  grid-column: 1 / -1;
}

.pageHeader {
  justify-self: center;
  display: flex;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ellipsisCell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.statusLabel {
  margin-bottom: auto;
  margin-left: units(0.5);
}
