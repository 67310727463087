@import 'spacings';

.property {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.label {
  margin-top: units(0.5);
}
