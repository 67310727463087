@import 'colours';
@import 'spacings';

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
}

.mainWrapper {
  flex-grow: 1;
  padding-top: units(0.5);
  margin-bottom: units(0.5);
}

.actionsWrapper {
  padding-bottom: units(0.5);
}

.elementWrapper {
  min-height: units(2);
  width: 100%;
}
