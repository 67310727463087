@import 'colours';
@import 'spacings';

.offer {
  background-color: $black-5;
}

.card {
  margin: units(1) 0;
}

.highlight {
  background-color: $error-light;
}
