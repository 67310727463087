.invoiceCard {
  .tableTools {
    display: flex;
    justify-content: space-between;
  }

  .select {
    min-width: 22em;
  }
}
