@import 'colours';
@import 'spacings';

.expandableNavItem {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: units(0.5) units(1) units(0.5) units(1.5);
  color: $black-70;
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: $black-5;
  }

  &.isActive {
    background-color: $black-10;
    font-weight: 500;
  }
}

.icon {
  margin-right: units(0.75);
  display: inline-flex;
}

.headerBtn {
  display: flex;
  align-items: center;
}

.label {
  flex-grow: 1;
}

.arrow.up {
  transform: rotate(180deg);
}

.children {
  margin-left: units(2);
  padding-left: units(0.5);
  border-left: units(0.125) solid $black-20;
  overflow: hidden;
  max-height: 0;

  &.expanded {
    max-height: unset;
  }
}
