@import 'colours';
@import 'spacings';
@import 'z-index';

.titleRow {
  width: 100%;
  padding: units(1);

  display: flex;
  justify-content: flex-end;

  background-color: $blue;
}

.expandButton {
  display: flex;
  align-items: center;

  color: $white;
  font-weight: 400;
  text-transform: uppercase;

  & > svg {
    margin-left: units(0.5);
  }
}

.tableFilters {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  padding: units(2) 0;

  .actionRow {
    grid-column: 1 / -1;

    margin-top: units(1);

    display: flex;
    justify-content: space-between;

    .controlsContainer,
    .buttonContainer {
      display: flex;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: units(1);
      }
    }
  }
}
