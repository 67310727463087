@import 'colours';
@import 'spacings';

.layout {
  display: grid;
  grid-template-columns: max-content 4fr;
  grid-template-rows: minmax(units(4), min-content) 1fr auto;
  grid-template-areas:
    'header  header'
    'side   content'
    'footer  footer';

  height: 100vh;

  .header {
    grid-area: header;
  }

  .side {
    grid-area: side;
    display: flex;
  }

  .sidebar {
    display: block;
    overflow: hidden;
  }

  .sidebarHidden {
    width: 0;
  }

  .sidebarToggleArea {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 0;
    height: 100%;
    background: $black-10;
  }

  .sidebarToggle {
    width: units(2);
    height: units(2);
    min-width: units(2);
    min-height: units(2);
    overflow: hidden;
    border-radius: 50%;
    filter: grayscale(1) brightness(2);
    margin-top: units(0.5);

    &:hover {
      filter: none;
    }

    span {
      padding: 0;
      margin: 0;
      line-height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .sidebarToggleShow {
    left: units(1);
  }

  .content {
    grid-area: content;
    overflow-y: auto;
    background-color: $black-5;
  }

  .footer {
    grid-area: footer;
  }
}
