@import 'spacings';
@import 'colours';

.cardBody {
  padding: units(1.5);
  background-color: $white;

  &:not(:last-child) {
    margin-bottom: units(0.5);
  }
}
