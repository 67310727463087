@import 'containers';
@import 'spacings';

.switchBerthModal {
  overflow-y: visible;
}

.content {
  width: container(s);
}

.instructions {
  white-space: pre-wrap;
  margin-bottom: units(2);
}

.spinnerWrapper {
  margin-bottom: units(2);
}

.select {
  width: 100%;
  margin-bottom: units(2);
}

.buttons {
  display: flex;
  justify-content: space-between;
}
